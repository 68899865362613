import React from "react";
import road from '../../assets/night_lights.jpg';
import NavIta from "./Navigation";
import Container from "@mui/material/Container";
import { colors } from "../../theme";
import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import TraficWidget from "./TraficWidget";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from 'react-redux';
import { useGetBusinessFollowersQuery } from "state/api";
import logo1 from '../../assets/protection_civile.png';
import logo2 from '../../assets/police.png';
import logo3 from '../../assets/ministere_sport_jeunesse.png';
import logo4 from '../../assets/dnsr.png';
import info1 from '../../assets/quisomme1.png'
import info2 from '../../assets/quisomme2.png'
import info3 from '../../assets/quisomme3.png'
import info4 from '../../assets/quisomme4.png'
import comment1 from '../../assets/comment1.png'
import comment2 from '../../assets/comment2.png'
import comment3 from '../../assets/comment3.png'
import VideoPlayer from "./VidStackIO";
import socials1 from '../../assets/calendar.png'
import socials2 from '../../assets/facebook.png'
import socials3 from '../../assets/instagram.png'
import socials4 from '../../assets/avis.png'
import Footer from "./Footer";
import GooglePlayStoreImg from '../../assets/GetItOnGooglePlay_Badge_Web_color_French.png';
import AppleAppStoreImg from '../../assets/Download_on_the_App_Store_Badge_FR_RGB_wht_100217.svg';
import InstagramChannel from '../../assets/instagram-channel.png';
import WhatsAppChannel from '../../assets/whatsapp-channel.png';
import TelegramChannel from '../../assets/telegram-channel.png';
import MessengerChannel from '../../assets/messenger-channel.png';
import ZelloCentre from '../../assets/centre.png';
import ZelloWatani from '../../assets/watani.png';
import ZelloDetente from '../../assets/detente.png';
import ZelloButton from "./ZelloBtn";
import ZelloConnectButton from "./ZelloConnectBtn";

const Hero = () => {
  const { data: FollowData, isLoading: FollowDataIsLoading, error: FollowDataError } = useGetBusinessFollowersQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

   const globalState = useSelector((state) => state.global);
  
   

  function formatFollowerCount(number) {
    if(!number){
      return
    }

    if (number >= 1000000) {
      // If the number is in the millions
      const formatted = (number / 1000000).toFixed(1); // Get the number in millions with 1 decimal point
      return formatted.endsWith('.0') ? `${parseInt(formatted)}M` : `${formatted}M`; // Remove .0 if present
    } else if (number >= 1000) {
      // If the number is in the thousands
      const formatted = (number / 1000).toFixed(1); // Get the number in thousands with 1 decimal point
      return formatted.endsWith('.0') ? `${parseInt(formatted)}K` : `${formatted}K`; // Remove .0 if present
    }
    // If the number is less than 1000, return the number as is
    
    return number.toString();
  }
  

  return (
    <div style={{ width: '100%', padding: "0em" }}>
      <section style={{
        width: "100%",
        minHeight: "100vh",
        padding: "20px", // Adjust this value as needed
        boxSizing: "border-box",
        backgroundImage: `url(${road})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
        <NavIta color={'white'} />

        <Container maxWidth="xl" sx={{ textAlign: 'left', marginTop: '2em' }}>
          
            <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{ fontWeight: "bold", color: "white", fontSize :  isMobile ? "2rem" : "5rem" , fontWeight : "Bold" , marginBottom : "0px" , marginTop : '1em' , fontFamily : "Raspoutine", marginTop : isMobile ? "2em" : "1em" }}
          >
            Info trafic Algérie - ITA
          </Typography>
         

          {isMobile ? 
          <Typography
          variant="h5"
          component="p"
          gutterBottom
          sx={{ color: "white", marginBottom: '1.5em' , fontSize : "1rem" , marginBottom : ".5em"  }}
        >
          Roulez en toute tranquillité sur les routes d'Algérie <br /> Nous sommes votre guide fiable
        </Typography>
        :
        <Typography
            variant="h5"
            component="p"
            gutterBottom
            sx={{ color: "white", marginBottom: '1.5em' , fontSize : "2rem" , marginBottom : ".5em" }}
          >
            Roulez en toute tranquillité sur les routes d'Algérie <br /> Nous sommes votre guide fiable
          </Typography>

          }
          
          {isMobile && 
          <Button
          variant="contained"
          component={Link}
          to= "/profile"
          sx={{
            display : "block" ,
            width: "12rem",
            height: "3rem",
            fontSize: "1rem",
            backgroundColor: '#AEE2F9',
            fontWeight: "Bold",
            color: "black",
            borderRadius: "10px",
            textTransform: "none",
            marginBottom :"1em",
            "&:hover": {
              backgroundColor: colors.accent,
              color: "white",
            },
          }}
        >
          
          {globalState.token && globalState.userRole ? "Mon profil" : "Se connecter"}
        </Button>
          }
          <Button
          variant="contained"
          component={Link}
          to={globalState.token && globalState.userRole === "user" ? "/profile" : globalState.token && globalState.userRole === "volunteer" ? "/profile" : "/join" }
          sx={{
            display : globalState.token && globalState.userRole === "user" ? "block" : globalState.token && globalState.userRole === "volunteer" ? "none" : "block" ,
            width: "12rem",
            height: "3rem",
            fontSize: "1rem",
            backgroundColor: colors.secondary,
            fontWeight: "Bold",
            color: "black",
            borderRadius: "10px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: colors.accent,
              color: "white",
            },
          }}
        >
          
          {globalState.token && globalState.userRole === "user" ? " Devenir bénévole" : globalState.token && globalState.userRole === "volunteer" ? "" : " Devenir bénévole" }
         {/* @TODO add a "en cour de validation state with a approvedVolenteering variable in user Schema" */}
        </Button>
        </Container>

        <TraficWidget />
      </section>

      <section
      style={{
        width: "100%",
        padding: isMobile ? "10px 20px" : "20px 60px", // Adjust padding for mobile
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2em",
      }}
    >
      <div>
        <Typography
          variant="h1"
          component="p"
          gutterBottom
          sx={{
            color: "black",
            marginBottom: '1.5em',
            fontSize: isMobile ? "2rem" : "4rem", // Adjust font size for mobile
            margin: ".5em 0em",
            textAlign: "center",
            maxWidth: "50rem",
          }}
        >
          Comment profiter de nos services
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row", // Stack vertically on mobile
          flexWrap: "nowrap",
          gap: isMobile ? "2em" : "1em", // Adjust gap for mobile
          width: "100%",
        }}
      >
        {/* Left side content (steps) */}
        <div style={{ width: isMobile ? "100%" : "40%" }}> {/* Adjust width for mobile */}
          {/* Step 1 */}
          <div
            style={{
              flex: '1',
              minWidth: '300px',
              maxWidth: '500px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <div
              style={{
                display: "flex",
                position: "relative",
                alignItems: "center",
                marginTop: "1em",
              }}
            >
              <img
                width={isMobile ? 80 : 120} // Adjust image size for mobile
                src={comment1}
                alt="Information"
                style={{ marginRight: '1rem', position: "relative", zIndex: 1, left: isMobile ? "1rem" : "1.5rem" }}
              />
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  position: "absolute",
                  left: isMobile ? "3.5em" : "4.5em",
                  zIndex: 2,
                  color: "black",
                  fontSize: isMobile ? "1.25rem" : "1.5rem", // Adjust font size for mobile
                  margin: ".5em 0em",
                }}
              >
                1. Installer l’application
              </Typography>
            </div>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                color: "#626261",
                fontSize: isMobile ? "0.9rem" : "1rem", // Adjust font size for mobile
                margin: ".5em 2em",
                textAlign: "left",
                maxWidth: "30em",
                paddingLeft: "3em",
              }}
            >
              Nous menons des campagnes sur la sécurité routière, axées sur l’usage du téléphone et le port de la ceinture.
            </Typography>
            <div style={{
              display : "flex",
              flexDirection :"row",
              flexWrap :"nowrap",
              gap:"1rem",
              justifyContent :'center',
              alignContent :'center',
              alignItems :"center",
              marginTop :"1em"
            }} >
              <a href="https://play.google.com/store/apps/details?id=com.loudtalks"><img width={isMobile ? 120 : 150} src={GooglePlayStoreImg} alt="Google Play Store" /></a>
              <a href="https://apps.apple.com/fr/app/zello-talkie-walkie/id508231856"><img width={isMobile ? 120 : 150} src={AppleAppStoreImg} alt="Apple App Store" /></a>
            </div>
          </div>

          {/* Step 2 */}
          <div
            style={{
              flex: '1',
              minWidth: '300px',
              maxWidth: '500px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <div
              style={{
                display: "flex",
                position: "relative",
                alignItems: "center",
                marginTop: "1em",
              }}
            >
              <img
                width={isMobile ? 80 : 120}
                src={comment2}
                alt="Information"
                style={{ marginRight: '1rem', position: "relative", zIndex: 1, left: isMobile ? "1rem" : "1.5rem" }}
              />
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  position: "absolute",
                  left: isMobile ? "3.5em" : "4.5em",
                  zIndex: 2,
                  color: "black",
                  fontSize: isMobile ? "1.25rem" : "1.5rem",
                  margin: ".5em 0em",
                }}
              >
                2. Créer un profil
              </Typography>
            </div>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                color: "#626261",
                fontSize: isMobile ? "0.9rem" : "1rem",
                margin: ".5em 2em",
                textAlign: "left",
                maxWidth: "30em",
                paddingLeft: "3em",
              }}
            >
              Créez votre compte avec vos informations personnelles.
            </Typography>
          </div>

          {/* Step 3 */}
          <div
            style={{
              flex: '1',
              minWidth: '300px',
              maxWidth: '500px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <div
              style={{
                display: "flex",
                position: "relative",
                alignItems: "center",
                marginTop: "1em",
              }}
            >
              <img
                width={isMobile ? 80 : 120}
                src={comment3}
                alt="Information"
                style={{ marginRight: '1rem', position: "relative", zIndex: 1, left: isMobile ? "1rem" : "1.5rem" }}
              />
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  position: "absolute",
                  left: isMobile ? "3.5em" : "4.5em",
                  zIndex: 2,
                  color: "black",
                  fontSize: isMobile ? "1.25rem" : "1.5rem",
                  margin: ".5em 0em",
                }}
              >
                3. Rejoindre nos canaux
              </Typography>
            </div>
            <div>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                color: "#626261",
                fontSize: isMobile ? "0.9rem" : "1rem",
                margin: ".5em 2em",
                textAlign: "left",
                maxWidth: "30em",
                paddingLeft: "3em",
              }}
            >
              Rejoignez nos canaux, pour un partage vocal direct, instantané et exclusif de l’information via 
            </Typography>
            <div style={{
              display :"flex",
              flexDirection : "row",
              justifyContent : "space-around",
              alignContent : "center",
              alignItems : "center",
              flexWrap : "nowrap",
              margin :"2em 0"
            }} >
              {/* <a  style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                overflow: "hidden",
                backgroundColor: "#f0f0f0", // Fallback background color
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                textDecoration: "none", // Ensure no underline
              }} href=""><img width={100} src={ZelloWatani} alt="Watani" /></a>
              <a style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                overflow: "hidden",
                backgroundColor: "#f0f0f0", // Fallback background color
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                textDecoration: "none", // Ensure no underline
              }}
              href=""><img width={100} src={ZelloCentre} alt="Centre" /></a>
              <a style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                overflow: "hidden",
                backgroundColor: "#f0f0f0", // Fallback background color
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                textDecoration: "none", // Ensure no underline
              }}
              href=""><img width={100} src={ZelloDetente} alt="Detente" /></a> */}
             {/*  <ZelloButton  img={ZelloWatani}
        mobileHref={`zello://9932bc2a?add_channel`}
        desktopHref="https://zello.com"
        alt="Zello Logo" 
         /> */}

         {/* <ZelloConnectButton  img={ZelloWatani}
        mobileHref={`zello://9932bc2a?add_channel`}
        desktopHref="https://zello.com"
        alt="Zello Logo"  /> */}

      <ZelloConnectButton
        channel="Info%20Trafic%20Alg%C3%A9rie%20-%20ITA%20%28Watani%29"
        channelName="Watani"
        channelShortHash="9932bc2a"
        desktopHref="https://zello.com/Watani"
        img={ZelloWatani}
      />

        <ZelloConnectButton
          channel="Info%20Trafic%20Alg%C3%A9rie%20-%20ITA"
          channelName="Centre"
          channelShortHash="f28dd17c"
          desktopHref="https://zello.com/traficalg"
          img={ZelloCentre}
        />

        <ZelloConnectButton
          channel="Info%20Trafic%20Alg%C3%A9rie%20-%20ITA%20%28D%C3%A9tente%29"
          channelName="Détente"
          channelShortHash="7e7c2c40"
          desktopHref="https://zello.com/traficalgd"
          img={ZelloDetente}
        />
            </div>
            </div>
          </div>
        </div>

        {/* Right side content (video player) */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            width: isMobile ? "auto" : "60%", // Adjust width for mobile
          }}
        >
          <VideoPlayer />
        </div>
      </div>

      <div>
      <Typography
          variant="h1"
          component="p"
          gutterBottom
          sx={{
            color: "black",
            marginBottom: "1.5em",
            fontSize: isMobile ? "1.5rem" : "2rem", // Adjust font size for mobile view
            margin: ".5em 0em",
            textAlign: "center",
          }}
        >
          Rejoignez nos canaux de diffusion pour plus d'informations
        </Typography>
        <ul style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row", // Column on mobile, row on desktop
              listStyleType: "none", // Remove default bullets
              padding: 0,
              marginTop: "3em",
              gap: isMobile ? "2em" : "6em", // Spacing between items
        }} >
          <li><a href="https://www.instagram.com/channel/Aba66qNDAvCGfnVW"><img width={isMobile ? 50 : 100} src={InstagramChannel} alt="Instagram" /></a></li>
          <li><a href="https://whatsapp.com/channel/0029VaCbWsx35fM2jPp76i3s"><img width={isMobile ? 50 : 100} src={WhatsAppChannel} alt="WhatsApp" /></a></li>
          <li><a href="https://t.me/traficalg"><img width={isMobile ? 50 : 100} src={TelegramChannel} alt="Telegram" /></a></li>
          <li><a href="https://m.me/j/AbYqakL0DiCkxksU/"><img width={isMobile ? 50 : 100} src={MessengerChannel} alt="Messenger" /></a></li>
        </ul>
      </div>
    </section>



      
      

    <section style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          background: "#F5F4F4",
          borderRadius: "25px",
          width: "100%",
          minHeight: "100vh",
          padding: isMobile ? "1em" : "2em", // Adjust padding for mobile view
        }}
      >
        <Typography
          variant="h1"
          component="p"
          gutterBottom
          sx={{
            color: "black",
            marginBottom: "1.5em",
            fontSize: isMobile ? "1.5rem" : "2rem", // Adjust font size for mobile view
            margin: ".5em 0em",
            textAlign: "center",
          }}
        >
          Qui sommes-nous vraiment ?
        </Typography>

        <Typography
          variant="p"
          component="p"
          gutterBottom
          sx={{
            color: "black",
            marginBottom: "1.5em",
            fontSize: isMobile ? "0.9rem" : "1rem", // Adjust font size for mobile view
            margin: ".5em 0em",
            textAlign: "center",
            maxWidth: isMobile ? "90%" : "40em", // Adjust width for mobile view
          }}
        >
          Nous sommes une association <strong>agréée par l'État</strong>, nous œuvrons pour l'information et la sécurité routière en Algérie. Notre but est de :
        </Typography>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: isMobile ? "1rem" : "2rem", // Adjust gap for mobile
            margin: "0 auto",
            padding: "2rem",
            maxWidth: "100%",
          }}
        >
          {/* First Block */}
          <div
            style={{
              flex: isMobile ? "1 1 100%" : "1 1 calc(50% - 2rem)", // Full width on mobile, 50% on desktop
              minWidth: "300px",
              maxWidth: "500px",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <img
                width={isMobile ? 100 : 150} // Smaller images on mobile
                src={info1}
                alt="Information"
                style={ isMobile ? { marginRight: "-0.5rem" } : { marginRight: "1rem"}}
              />
              <Typography
                variant="h2"
                gutterBottom
                sx={{ color: "black", fontSize: isMobile ? "2rem" : "2.5rem", margin: ".5em 0em" }}
              >
                Sensibiliser
              </Typography>
            </div>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                color: "black",
                fontSize: isMobile ? "0.9rem" : "1rem",
                margin: ".5em 2em",
                textAlign: "center",
                maxWidth: "30em",
                paddingLeft: "3em",
              }}
            >
              L’association informe le public sur les risques liés à la conduite, tels que l’excès de vitesse, la conduite en état d'ivresse, le non-respect des règles de circulation, etc.
            </Typography>
          </div>

          {/* Repeat for other blocks, with the same responsive adjustments */}
          {/* Second Block */}
          <div
            style={{
              flex: isMobile ? "1 1 100%" : "1 1 calc(50% - 2rem)",
              minWidth: "300px",
              maxWidth: "500px",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <img
                width={isMobile ? 100 : 150}
                src={info2}
                alt="Information"
                style={{ marginRight: "1rem" }}
              />
              <Typography
                variant="h2"
                gutterBottom
                sx={{ color: "black", fontSize: isMobile ? "2rem" : "2.5rem", margin: ".5em 0em" }}
              >
                Conseiller
              </Typography>
            </div>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                color: "black",
                fontSize: isMobile ? "0.9rem" : "1rem",
                margin: ".5em 2em",
                textAlign: "center",
                maxWidth: "30em",
                paddingLeft: "3em",
              }}
            >
              Nous donnons des conseils sur les règles de sécurité routière et les bonnes pratiques de conduite.
            </Typography>
          </div>

          {/* Third Block */}
          <div
            style={{
              flex: isMobile ? "1 1 100%" : "1 1 calc(50% - 2rem)",
              minWidth: "300px",
              maxWidth: "500px",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <img
                width={isMobile ? 100 : 150}
                src={info3}
                alt="Information"
                style={{ marginRight: "1rem" }}
              />
              <Typography
                variant="h2"
                gutterBottom
                sx={{ color: "black", fontSize: isMobile ? "2rem" : "2.5rem", margin: ".5em 0em" }}
              >
                Informer
              </Typography>
            </div>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                color: "black",
                fontSize: isMobile ? "0.9rem" : "1rem",
                margin: ".5em 2em",
                textAlign: "center",
                maxWidth: "30em",
                paddingLeft: "3em",
              }}
            >
              Nous vous offrons l’information du trafic routier en temps réel.
            </Typography>
          </div>

          {/* Fourth Block */}
          <div
            style={{
              flex: isMobile ? "1 1 100%" : "1 1 calc(50% - 2rem)",
              minWidth: "300px",
              maxWidth: "500px",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                marginTop: "1em",
              }}
            >
              <img
                width={isMobile ? 100 : 150}
                src={info4}
                alt="Information"
                style={ isMobile ? { marginRight: "-1rem" } : { marginRight: "1rem"}}
              />
              <Typography
                variant="h2"
                gutterBottom
                sx={{ color: "black", fontSize: isMobile ? "2rem" : "2.5rem", margin: ".5em 0em" }}
              >
                Compagnes preventive
              </Typography>
            </div>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                color: "black",
                fontSize: isMobile ? "0.9rem" : "1rem",
                margin: ".5em 2em",
                textAlign: "center",
                maxWidth: "30em",
                paddingLeft: "3em",
              }}
            >
              Nous menons des campagnes sur la sécurité routière, axées sur l’usage du téléphone et le port de la ceinture.
            </Typography>
          </div>
        </div>
      </div>
    </section>


    <section style={{
      padding: "20px",
    }}>
      <div>
        <Typography
          variant="h1"
          component="p"
          gutterBottom
          sx={{
            color: "black",
            marginBottom: '1.5em',
            fontSize: isMobile ? "1.5rem" : "2rem", // Adjust font size based on mobile view
            margin: ".5em 0em",
            textAlign: "center"
          }}
        >
          Ils nous font confiance
        </Typography>
        <div style={{
          display: 'grid',
          gridTemplateColumns: isMobile ? 'repeat(2, auto)' : 'repeat(4, auto)', // 2 columns on mobile, 4 on desktop
          gap: '3em',
          placeItems: 'center',
          margin: isMobile ? '2em auto' : '5em auto', // Adjust margin for mobile
          justifyContent: "center"
        }}>
          <img width={isMobile ? 100 : 130} src={logo1} alt="Logo 1" /> {/* Adjust image size for mobile */}
          <img width={isMobile ? 100 : 130} src={logo2} alt="Logo 2" />
          <img width={isMobile ? 100 : 130} src={logo3} alt="Logo 3" />
          <img width={isMobile ? 100 : 130} src={logo4} alt="Logo 4" />
        </div>
      </div>
    </section>
        
      
    <section style={{
      marginTop: "5em",
      padding: isMobile ? "10px" : "20px", // Adjust padding for mobile
    }}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1em",
        background: "#F5F4F4",
        borderRadius: "25px",
        width: "100%",
        minHeight: "100vh",
        padding: isMobile ? "1em" : "2em", // Adjust padding for mobile
      }}>
        <Typography
          variant="h1"
          component="p"
          gutterBottom
          sx={{
            color: "black",
            marginBottom: '1.5em',
            fontSize: isMobile ? "2rem" : "4rem", // Adjust font size for mobile
            margin: ".5em 0em",
            textAlign: "center"
          }}
        >
          Les chiffres marquants de notre activité
        </Typography>

        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '2rem',
          margin: '0 auto',
          padding: '2rem',
          maxWidth: '1200px'
        }}>
          {/* First Block */}
          <div style={{
            flex: '1 1 calc(50% - 2rem)',
            minWidth: isMobile ? '100%' : '300px', // Adjust width for mobile
            maxWidth: '500px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            textAlign: isMobile ? 'center' : 'left' // Adjust text alignment for mobile
          }}>
            <span style={{
              fontSize: isMobile ? "1.5em" : "2em", // Adjust font size for mobile
              color: '#333333'
            }}>Fondé en</span>
            <div style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              flexDirection: "row", // Stack vertically on mobile
              textAlign: "center"
            }}>
              <img width={isMobile ? 100 : 150} src={socials1} alt="Information" style={{ position: "relative", left: "4em", zIndex: "0" }} />
              <Typography
                variant="h2"
                gutterBottom
                sx={{ color: "black", fontSize: isMobile ? "2rem" : "2.5rem", fontWeight: "bold", margin: ".5em 0em", zIndex: "1" }}
              >
                2015
              </Typography>
            </div>
          </div>

          {/* Second Block */}
          <div style={{
            flex: '1 1 calc(50% - 2rem)',
            minWidth: isMobile ? '100%' : '300px',
            maxWidth: '500px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            textAlign: isMobile ? 'center' : 'left'
          }}>
            <span style={{
              fontSize: isMobile ? "1.5em" : "2em",
              color: '#333333'
            }}>Abonnés sur Facebook</span>
            <div style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              flexDirection: "row",
              textAlign: "center"
            }}>
              <img width={isMobile ? 100 : 150} src={socials2} alt="Information" style={{ position: "relative", left: "4em", zIndex: "0" }} />
              <Typography
                variant="h2"
                gutterBottom
                sx={{ color: "black", fontSize: isMobile ? "2rem" : "2.5rem", fontWeight: "bold", margin: ".5em 0em", zIndex: "1" }}
              >
                {formatFollowerCount(FollowData?.facebook)}
              </Typography>
            </div>
          </div>

          {/* Third Block */}
          <div style={{
            flex: '1 1 calc(50% - 2rem)',
            minWidth: isMobile ? '100%' : '300px',
            maxWidth: '500px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            textAlign: isMobile ? 'center' : 'left'
          }}>
            <span style={{
              fontSize: isMobile ? "1.5em" : "2em",
              color: '#333333'
            }}>Abonnés Instagram</span>
            <div style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              flexDirection: "row",
              textAlign: "center"
            }}>
              <img width={isMobile ? 100 : 150} src={socials3} alt="Information" style={{ position: "relative", left: "4em", zIndex: "0" }} />
              <Typography
                variant="h2"
                gutterBottom
                sx={{ color: "black", fontSize: isMobile ? "2rem" : "2.5rem", fontWeight: "bold", margin: ".5em 0em", zIndex: "1" }}
              >
                {formatFollowerCount(FollowData?.instagram)}
              </Typography>
            </div>
          </div>

          {/* Fourth Block */}
          <div style={{
            flex: '1 1 calc(50% - 2rem)',
            minWidth: isMobile ? '100%' : '300px',
            maxWidth: '500px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            textAlign: isMobile ? 'center' : 'left'
          }}>
            <span style={{
              fontSize: isMobile ? "1.5em" : "2em",
              color: '#333333'
            }}>Avis</span>
            <div style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              flexDirection:"row",
              textAlign: "center"
            }}>
              <img width={isMobile ? 100 : 150} src={socials4} alt="Information" style={{ position: "relative", left: "4em", zIndex: "0" }} />
              <Typography
                variant="h2"
                gutterBottom
                sx={{ color: "black", fontSize: isMobile ? "2rem" : "2.5rem", fontWeight: "bold", margin: ".5em 0em", zIndex: "1" }}
              >
                {`4.7/5 ( 1521 )`}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </section>

      <Footer />

    </div>
  );
};

export default Hero;
