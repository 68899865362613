import {
    Box, Typography, Radio, FormControlLabel, RadioGroup, FormLabel,
    FormControl, TextField, IconButton, Button, CircularProgress
  } from "@mui/material";
  import EditIcon from "@mui/icons-material/Edit";
  import SaveIcon from "@mui/icons-material/Save";
  import { useEffect, useState } from "react";
  import { useSelector } from "react-redux";
  import { motion } from "framer-motion";
  import { useUserProfileInfosMutation, useUpdateUserMutation } from "state/api";
  import { useTheme } from "@mui/material/styles";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
  
const wilayas = [
    "Adrar", "Chlef", "Laghouat", "Oum El Bouaghi", "Batna", "Bejaia", "Biskra", "Bechar", "Blida",
    "Bouira", "Tamanrasset", "Tebessa", "Tlemcen", "Tiaret", "Tizi Ouzou", "Alger", "Djelfa",
    "Jijel", "Setif", "Saida", "Skikda", "Sidi Bel Abbes", "Annaba", "Guelma", "Constantine",
    "Medea", "Mostaganem", "Msila", "Mascara", "Ouargla", "Oran", "El Bayadh", "Illizi",
    "Bordj Bou Arreridj", "Boumerdes", "El Tarf", "Tindouf", "Tissemsilt", "El Oued",
    "Khenchela", "Souk Ahras", "Tipaza", "Mila", "Ain Defla", "Naama", "Ain Temouchent",
    "Ghardaia", "Relizane"
  ];
  
  const slideIn = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50 },
  };
  
  
  const textFieldStyles = {
    '& label.Mui-focused': { color: '#0085FF' },
    '& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: '#0085FF' },
      '&:hover fieldset': { borderColor: '#0070D2' },
      '&.Mui-focused fieldset': { borderColor: '#005BB5' },
    },
  };
  
  const MemberCard = () => {
    const userId = useSelector((state) => state.global.userId);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  
    const [fetchUserProfile] = useUserProfileInfosMutation();
    const [updateUser] = useUpdateUserMutation();
  
    const [editField, setEditField] = useState(null);
    const [isVolunteer, setIsVolunteer] = useState(null);
    const [loading, setLoading] = useState(true);
  
    // Individual state values
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [wilaya, setWilaya] = useState('');
    const [commune, setCommune] = useState('');
    const [phone, setPhone] = useState('');
    const [transportation, setTransportation] = useState('');
    const [profession, setProfession] = useState('');
    const [otherSkills, setOtherSkills] = useState('');
  
    useEffect(() => {
      const fetchProfile = async () => {
        if (!userId) return;
        try {
          const res = await fetchUserProfile({ userId }).unwrap();
          setIsVolunteer(res?.isVolunteer || false);
  
          const v = res?.volunteer || {};
          setName(v.name || '');
          setLastName(v.lastName || '');
          setBirthDate(v.birthDate || '');
          setWilaya(v.wilaya || '');
          setCommune(v.commune || '');
          setPhone(v.phone || '');
          setTransportation(v.transportation || '');
          setProfession(v.profession || '');
          setOtherSkills(v.otherSkills || '');
        } catch (err) {
          console.error("Failed to fetch volunteer info", err);
        } finally {
          setLoading(false);
        }
      };
      fetchProfile();
    }, [userId, fetchUserProfile]);
  
    const handleSave = async (field, value) => {
      try {
        await updateUser({
          userId,
          updates: { [`personalInfo.${field}`]: value }
        }).unwrap();
        setEditField(null);
      } catch (err) {
        console.error("Failed to save", err);
        alert("Erreur lors de la mise à jour !");
      }
    };
  
    const handleSubmitVolunteerForm = async (e) => {
      e.preventDefault();
      try {
        await updateUser({
          userId,
          updates: {
            volunteer: true,
            personalInfo: {
              name,
              lastName,
              dateOfBirth: birthDate,
              wilaya,
              commune,
              phone,
              transportation,
              profession,
              otherSkills,
            },
          },
        }).unwrap();
        setIsVolunteer(true);
      } catch (err) {
        console.error("Error submitting volunteer info", err);
        alert("Erreur lors de l'enregistrement !");
      }
    };
  
    if (loading) {
      return <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}><CircularProgress /></Box>;
    }
  
    return (
      <Box sx={{
        width: "50rem", margin: "auto", minHeight: "50vh", padding: "20px",
        borderRadius: "8px", boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
        backgroundColor: "#F9FAFC", fontFamily: "Arial, sans-serif", textAlign: "left"
      }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "20px", mb: 2 }}>
          Carte Membre - Volontaire
        </Typography>
  
        {!isVolunteer ? (
          <motion.div key="form" variants={slideIn} initial="hidden" animate="visible" exit="exit" transition={{ duration: 0.5 }}>
            <Typography variant="h3" sx={{ fontSize: 30, fontFamily: 'raspoutine', fontWeight: 'bold', color: '#0085FF', mb: 2 }}>
              Devenir bénévole
            </Typography>
            <Box component="form" onSubmit={handleSubmitVolunteerForm} sx={{
              textAlign: "left", maxHeight: isMobile ? "70vh" : "60vh", overflowY: "auto", pr: 1,
            }}>
              <TextField label="Prénom" fullWidth margin="normal" value={name} onChange={(e) => setName(e.target.value)} sx={textFieldStyles} />
              <TextField label="Nom" fullWidth margin="normal" value={lastName} onChange={(e) => setLastName(e.target.value)} sx={textFieldStyles} />
              <TextField label="Date de naissance" type="date" fullWidth margin="normal" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} InputLabelProps={{ shrink: true }} sx={textFieldStyles} />
              <TextField select label="Wilaya de résidence" fullWidth margin="normal" value={wilaya} onChange={(e) => setWilaya(e.target.value)} SelectProps={{ native: true }} sx={textFieldStyles}>
                <option value=""></option>
                {wilayas.map((w, i) => <option key={i} value={w}>{w}</option>)}
              </TextField>
              <TextField label="Commune" fullWidth margin="normal" value={commune} onChange={(e) => setCommune(e.target.value)} sx={textFieldStyles} />
              <PhoneInput country="dz" value={phone} onChange={(p) => setPhone(p)} inputStyle={{
                width: '100%', height: '56px', borderRadius: '5px', border: '1px solid #0085FF',
                paddingLeft: '58px', fontSize: '16px'
              }} buttonStyle={{ borderRadius: '5px', border: 'none', background: 'transparent', margin: "10px" }} />
              <FormControl fullWidth margin="normal">
                <FormLabel sx={{ color: '#006bbf' }}>Moyens de locomotion</FormLabel>
                <RadioGroup row value={transportation} onChange={(e) => setTransportation(e.target.value)} sx={{ gap: isMobile ? '.5em' : '1.5em' }}>
                  {["Sans", "Moto", "Voiture", "Fourgonnette", "Fourgon", "Camion"].map((mode) => (
                    <FormControlLabel key={mode} value={mode} control={<Radio sx={{ color: '#006bbf' }} />} label={mode} sx={{ color: '#006bbf' }} />
                  ))}
                </RadioGroup>
              </FormControl>
              <TextField label="Profession" fullWidth margin="normal" value={profession} onChange={(e) => setProfession(e.target.value)} sx={textFieldStyles} />
              <TextField label="Autres compétences" fullWidth margin="normal" value={otherSkills} onChange={(e) => setOtherSkills(e.target.value)} sx={textFieldStyles} />
              <Button type="submit" variant="contained" sx={{ mt: 2 }}>Envoyer ma candidature volontaire</Button>
            </Box>
          </motion.div>
        ) : (
          <>
            {[
              { label: "Prénom", field: "name", value: name, setValue: setName },
              { label: "Nom", field: "lastName", value: lastName, setValue: setLastName },
              { label: "Téléphone", field: "phone", value: phone, setValue: setPhone },
              { label: "Date de naissance", field: "birthDate", value: birthDate, setValue: setBirthDate },
              { label: "Wilaya", field: "wilaya", value: wilaya, setValue: setWilaya },
              { label: "Commune", field: "commune", value: commune, setValue: setCommune },
              { label: "Profession", field: "profession", value: profession, setValue: setProfession },
              { label: "Compétences", field: "otherSkills", value: otherSkills, setValue: setOtherSkills },
              { label: "Moyen de transport", field: "transportation", value: transportation, setValue: setTransportation },
            ].map(({ label, field, value, setValue }) => (
              <Box key={field} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                {editField === field ? (
                  <TextField fullWidth value={value} onChange={(e) => setValue(e.target.value)} />
                ) : (
                  <Typography sx={{ fontWeight: "bold", flex: 1, fontSize: "14px" }}>
                    {label} : <Typography component="span" sx={{ fontWeight: "normal" }}>{value || "N/A"}</Typography>
                  </Typography>
                )}
                <IconButton onClick={() => editField === field ? handleSave(field, value) : setEditField(field)}>
                  {editField === field ? <SaveIcon /> : <EditIcon />}
                </IconButton>
              </Box>
            ))}
          </>
        )}
      </Box>
    );
  };
  
  export default MemberCard;
  