import React from 'react';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const InputFieldWithImage = ({ type, name, id, img, alt, color, placeHolder, onChangeCallback }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className="InputContainer" style={{ position: 'relative', userSelect: 'none' }}>
      <input
        type={type}
        name={name}
        id={id}
        style={{
          padding: '0px 20px 0px 60px',
          width: '20em',
          height: '4em',
          border: `2px solid ${color}`,
          borderRadius: '77px',
          position:"relative",
          boxShadow: "rgba(100, 100, 111, 0.25) 0px 4px 4px 0px"
        }}
        placeholder={placeHolder}
        className='wezignInput'
        onChange={(e) => {
          if(!onChangeCallback){
            return
          }
          onChangeCallback(e.target.value)
        }}
      />
      <div style={{ display: 'flex', position: 'absolute', left: '20px', top: '50%', transform: 'translateY(-50%)', height: '20px', width: '20px' }}>
        <img src={img} alt={alt} style={{ userSelect: 'none' }} />
        <div style={{ borderRight: `2px solid black`, height: '100%', marginLeft: isMobile ? '-15px' : '5px' }}></div>
      </div>
    </div>
  );
};

export default InputFieldWithImage;
