import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  Divider,
  Container
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import PhoneIcon from "@mui/icons-material/Phone";
import Logo from "../../assets/logo_ita.png";
import avatar from "../../assets/avatar.svg";
import { colors } from "../../theme";
import { useGetUserQuery } from "state/api";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const pages = [
  { name: "Accueil", path: "/" },
  { name: "Contactez-nous", path: "/contact" },
];

const NavIta = ({ color }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const userId = useSelector((state) => state.global.userId);
  const { data: user } = useGetUserQuery(userId);
  const currentLocation = useLocation();
  const hiddenAvatarRoutes = ["/profile", "/vehicule"];
  const isAvatarHidden = hiddenAvatarRoutes.some((route) =>
    currentLocation.pathname.startsWith(route)
  );

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const navLinks = (
    <>
      {pages.map((page) => (
        <ListItem key={page.name} sx={{ px: 0, py: 1 }}>
          <Typography variant="h6">
            <Link
              to={page.path}
              onClick={toggleDrawer(false)}
              style={{ textDecoration: "none", color: "#000", fontSize: "1.2rem" }}
            >
              {page.name}
            </Link>
          </Typography>
        </ListItem>
      ))}
      <Divider sx={{ my: 2 }} />
      {user ? (
        <Button
          variant="contained"
          sx={{
            backgroundColor: colors.secondary,
            color: "black",
            fontWeight: "bold",
            width: "100%",
            borderRadius: "8px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: colors.accent,
              color: "white",
            },
          }}
          component={Link}
          to="/profile"
          onClick={toggleDrawer(false)}
        >
          Mon Profil
        </Button>
      ) : (
        <Button
          variant="contained"
          sx={{
            backgroundColor: colors.secondary,
            color: "black",
            fontWeight: "bold",
            width: "100%",
            borderRadius: "8px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: colors.accent,
              color: "white",
            },
          }}
          component={Link}
          to="/auth"
          onClick={toggleDrawer(false)}
        >
          Se connecter
        </Button>
      )}
    </>
  );

  const drawerContent = (
    <Box
      sx={{
        width: 300,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: 3,
        backgroundColor: "#fff",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
          <Box component="img" src={Logo} alt="Logo" sx={{ height: 40 }} />
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List disablePadding>{navLinks}</List>
      </Box>

      <Box>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, my: 2 }}>
          <IconButton href="https://www.instagram.com/traficalg/" target="_blank" sx={{ color: "#E1306C" }}>
            <InstagramIcon />
          </IconButton>
          <IconButton href="https://www.whatsapp.com/channel/0029VaCbWsx35fM2jPp76i3s" target="_blank" sx={{ color: "#25D366" }}>
            <WhatsAppIcon />
          </IconButton>
          <IconButton href="https://t.me/traficalg" target="_blank" sx={{ color: "#0088cc" }}>
            <TelegramIcon />
          </IconButton>
        </Box>
        <Button
          href="tel:+213556125475"
          variant="outlined"
          startIcon={<PhoneIcon />}
          sx={{
            width: "100%",
            color: "#000",
            borderColor: "#000",
            textTransform: "none",
          }}
        >
          Appelez-nous maintenant
        </Button>
      </Box>
    </Box>
  );

  return (
    <AppBar position="static" sx={{ background: "rgba(255,255,255,0.08)" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Box
              component="img"
              src={Logo}
              alt="Info Trafic Algérie - ITA"
              sx={{ mr: 2, display: "flex", height: 40, cursor: "pointer" }}
            />
          </Link>

          {isMobile ? (
            <Box sx={{ ml: "auto", display: "flex", alignItems: "center", gap: 1 }}>
              {user && !isAvatarHidden && (
                <IconButton component={Link} to="/profile">
                  <Box
                    component="img"
                    src={avatar}
                    alt="avatar"
                    sx={{
                      width: 36,
                      height: 36,
                      borderRadius: "50%",
                      border: "2px solid white",
                    }}
                  />
                </IconButton>
              )}
              <IconButton onClick={toggleDrawer(true)} color="inherit">
                <MenuIcon />
              </IconButton>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", gap: 2 }}>
                {pages.map((page) => (
                  <Button
                    key={page.name}
                    component={Link}
                    to={page.path}
                    sx={{ color: color, textTransform: "none" }}
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>
              {user ? (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colors.secondary,
                    fontWeight: "Bold",
                    color: "black",
                    borderRadius: "10px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: colors.accent,
                      color: "white",
                    },
                  }}
                  component={Link}
                  to="/profile"
                >
                  Mon Profil
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colors.secondary,
                    fontWeight: "Bold",
                    color: "black",
                    borderRadius: "10px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: colors.accent,
                      color: "white",
                    },
                  }}
                  component={Link}
                  to="/auth"
                >
                  Se connecter
                </Button>
              )}
            </Box>
          )}
        </Toolbar>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          sx={{ zIndex: 1301 }}
        >
          {drawerContent}
        </Drawer>
      </Container>
    </AppBar>
  );
};

export default NavIta;
